<template>
  <el-dialog
    title="分配角色"
    width="760px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form ref="paramsFormRef" label-width="100px" v-loading="dialogLoading" :model="paramsForm">
      <el-form-item
        label="已选择角色"
        prop="grantRoleIdList"
        :rules="{ required: true, message: '请选择要分配的角色', trigger: 'change' }"
      >
        <el-select
          style="width: 100%"
          multiple
          placeholder="请选择要分配的角色"
          v-model="paramsForm.grantRoleIdList"
        >
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onConfirmSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getRoleList, getUserOwnRole, postGrantRole } from '@/api/main/system/userMana.js';

export default {
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      submitLoading: false,
      roleList: [],
      paramsForm: {
        id: '',
        grantRoleIdList: []
      }
    };
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.dialogLoading = true;
      // 获取所有角色列表
      getRoleList()
        .then(res => {
          this.roleList = res.data;
          // 获取该用户所拥有的角色
          return getUserOwnRole({ id });
        })
        .then(res => {
          this.paramsForm.id = id;
          this.paramsForm.grantRoleIdList = res.data;
        })
        .catch(err => {
          this.dialogVisible = false;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    // 取消
    onCancel() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
    },
    // 确定
    onConfirmSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        postGrantRole(this.paramsForm)
          .then(res => {
            this.$message.success('角色分配成功！');
            this.onCancel();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>

<style lang="less" scoped>
</style>

<template>
  <!-- 新增/编辑 对话框 -->
  <el-dialog
    title="用户信息"
    width="760px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form
      inline
      ref="paramsFormRef"
      label-width="80px"
      style="padding-top:1px"
      v-loading="dialogLoading"
      :model="paramsForm"
      :rules="rules"
    >
      <el-divider content-position="left">基本信息</el-divider>
      <el-form-item label="账号" prop="account">
        <el-input
          class="w-250"
          placeholder="请输入账号"
          maxlength="20"
          show-word-limit
          v-model.trim="paramsForm.account"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
          class="w-250"
          placeholder="请输入姓名"
          maxlength="10"
          show-word-limit
          v-model.trim="paramsForm.name"
        ></el-input>
      </el-form-item>
      <!-- 编辑时不用传密码 -->
      <el-form-item v-if="!paramsForm.id" label="密码" prop="password">
        <el-input
          class="w-250"
          placeholder="请输入密码"
          show-password
          v-model.trim="paramsForm.password"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="!paramsForm.id" label="重复密码" prop="checkPassword">
        <el-input
          class="w-250"
          placeholder="请再次输入密码"
          show-password
          v-model.trim="paramsForm.checkPassword"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input class="w-250" placeholder="请输入手机号" v-model.trim="paramsForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="电话号" prop="tel">
        <el-input
          class="w-250"
          placeholder="请输入电话号"
          maxlength="20"
          show-word-limit
          v-model.trim="paramsForm.tel"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickName">
        <el-input
          class="w-250"
          placeholder="请输入昵称"
          maxlength="10"
          show-word-limit
          v-model.trim="paramsForm.nickName"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="paramsForm.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input
          style="width: 590px"
          placeholder="请输入邮箱"
          maxlength="50"
          show-word-limit
          v-model.trim="paramsForm.email"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          style="width: 590px"
          type="textarea"
          :rows="4"
          show-word-limit
          :maxlength="200"
          placeholder="请输入备注"
          v-model.trim="paramsForm.remark"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">员工信息</el-divider>
<!--      <el-form-item label="组织" prop="sysEmpParam.orgId">-->
<!--        <el-cascader-->
<!--          ref="cascaderRef"-->
<!--          class="w-250"-->
<!--          placeholder="请选择"-->
<!--          :options="orgTree"-->
<!--          :props="defaultProps"-->
<!--          v-model="paramsForm.sysEmpParam.orgId"-->
<!--          @change='handleChange'-->
<!--        ></el-cascader>-->
<!--      </el-form-item>-->
      <el-form-item>
        <span slot="label"><span style="margin-right: 4px; color: #f5222d; font-size: 14px">*</span>组织</span>
<!--        <el-tree :data="orgTree" :props="defaultProps" @node-click="popoverHide"></el-tree>-->
        <tree-select :data="orgTree"
                     :defaultProps="defaultProps"
                     :nodeKey="nodeKey"
                     :width='226'
                     :checkedKeys="defaultCheckedKeys"
                     :clearable="true"
                     @popoverHide="popoverHide"></tree-select>
      </el-form-item>
      <el-form-item label="职位" prop="sysEmpParam.posIdList">
        <el-select
          class="w-250"
          multiple
          placeholder="请选择职位"
          v-model="paramsForm.sysEmpParam.posIdList"
        >
          <el-option v-for="item in posList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工号" prop="jobNum">
        <el-input
          maxlength="50"
          show-word-limit
          class="w-250"
          placeholder="请输入工号"
          v-model.trim="paramsForm.sysEmpParam.jobNum"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getPosList, postUserInfo, getUserDetail } from '@/api/main/system/userMana.js';
import { mapGetters } from 'vuex';
import store from '@/utils/store';
import TreeSelect from '@/components/treeSelect/index.vue';
export default {
  props: {
    orgTree: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TreeSelect
  },
  computed: {
    ...mapGetters(['appInfo'])
  },
  data() {
    // 校验密码
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.paramsForm.checkPassword !== '') {
          this.$refs.paramsFormRef.validateField('checkPassword');
        }
        callback();
      }
    };
    // 校验重复密码
    let validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.paramsForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    // 检验账号
    let validateAccount = (rule, value, callback) => {
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (value === '') {
        callback(new Error('请输入账号'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的账号'));
      } else {
        if(this.paramsForm.phone !== '') {
          this.$refs.paramsFormRef.validateField('phone');
        }
        callback();
      }
    }
    // 检验手机号
    let validateCellphone = (rule, value, callback) => {
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else if (value !== this.paramsForm.account) {
        callback(new Error('与输入账号不一致!'));
      } else {
        callback();
      }
    };
    // 检验邮箱
    let validateEmail = (rule, value, callback) => {
      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value && !reg.test(value)) {
        callback(new Error('请输入正确的邮箱号'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogLoading: false,
      submitLoading: false,
      nodeKey: 'id',
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      posList: [],
      rules: {
        account: [{ required: true, validator: validateAccount, trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符之间', trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validateCheckPass, trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符之间', trigger: 'blur' }
        ],
        phone: [{ required: true, validator: validateCellphone, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        'sysEmpParam.orgId': [{ required: true, message: '请选择组织', trigger: 'change' }],
        'sysEmpParam.posIdList': [{ required: true, message: '请选择职位', trigger: 'change' }]
      },
      paramsForm: {
        id: '',
        account: '',
        name: '',
        password: '',
        checkPassword: '',
        nickName: '',
        sex: 3,
        phone: '',
        tel: '',
        email: '',
        remark: '',
        sysEmpParam: {
          jobNum: '',
          orgId: '',
          orgName: '',
          posIdList: []
        },
        sysUserRoles: []
      }
    };
  },
  methods: {
    // 选择组织
    popoverHide(checkedIds, checkedData) {
      this.paramsForm.sysEmpParam.orgId = checkedData.id
      this.paramsForm.sysEmpParam.orgName = checkedData.title
    },
    // 获取用户详情并回显
    getUserDetailData(data) {
      this.dialogLoading = true;
      getUserDetail({ id: data.id, account: data.account })
        .then(res => {
          const userInfo = res.data;
          if(userInfo.sysEmpInfo) {
            this.defaultCheckedKeys = [userInfo.sysEmpInfo.orgId]
          }
          this.$nextTick(() => {
            this.paramsForm.id = userInfo.id;
            this.paramsForm.account = userInfo.account;
            this.paramsForm.name = userInfo.name;
            this.paramsForm.phone = userInfo.phone;
            this.paramsForm.tel = userInfo.tel;
            this.paramsForm.nickName = userInfo.nickName;
            this.paramsForm.sex = userInfo.sex;
            this.paramsForm.email = userInfo.email;
            this.paramsForm.remark = userInfo.remark;
            this.paramsForm.sysEmpParam.jobNum = userInfo.sysEmpInfo.jobNum;
            this.paramsForm.sysEmpParam.orgId = userInfo.sysEmpInfo.orgId;
            this.paramsForm.sysEmpParam.orgName = userInfo.sysEmpInfo.orgName;
            this.paramsForm.sysEmpParam.posIdList = userInfo.sysEmpInfo.positions?.map(item => item.posId);
          });
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    // 获取职位下拉列表
    getPosListData() {
      getPosList().then(res => {
        this.posList = res.data;
      });
    },
    open(data) {
      // 传入数据表示修改
      if (data) {
        this.getUserDetailData(data);
      }
      this.getPosListData();
      this.dialogVisible = true;
    },
    // 点击取消
    onCancel() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm.id = '';
      this.paramsForm.sysEmpParam.orgName = '';
      this.paramsForm.sysEmpParam.orgId = '';
      this.defaultCheckedKeys = []
    },
    // 点击确定
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        if (!this.paramsForm.sysEmpParam.orgId) {
          this.$message.warning('请选择组织')
          return
        }
        const { id } = this.paramsForm;
        this.paramsForm.appId = this.appInfo.appId;
        this.paramsForm.orgId = this.paramsForm.sysEmpParam.orgId;
        // console.log(this.paramsForm);
        this.submitLoading = true;
        postUserInfo(this.paramsForm)
          .then(res => {
            this.onCancel();
            this.$emit('onSuccess', id);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.w-250 {
  width: 250px;
}
/deep/ .el-loading-mask {
  background-color: #fff;
}
</style>

<template>
  <div>
    <div class="title">用户管理</div>
    <div class="content">
      <div class="tree">
        <div class="tree-title">目录结构</div>
        <el-tree
          ref="orgTreeRef"
          highlight-current
          :expand-on-click-node="false"
          default-expand-all
          node-key="id"
          :data="orgTree"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="right-table">
        <div class="edit-form">
          <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="queryForm.searchValue" placeholder="请输入姓名/手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="top-search">
          <el-button type="primary" icon="el-icon-plus" @click="$refs.infoDialogRef.open()">新建</el-button>
          <div>
            <el-button
              :disabled="!selectedIdList.length"
              :loading="exportLoading"
              @click="handleExport"
            >导出</el-button>
            <el-button
              type="danger"
              :disabled="!selectedIdList.length"
              @click="handleDelete(selectedIdList)"
            >批量删除</el-button>
          </div>
        </div>
        <el-table
          stripe
          size="medium"
          :data="userList"
          v-loading="tableLoading"
          style="width: 100%; margin-top: 26px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column align="center" width="60" type="selection"></el-table-column>
          <el-table-column align="center" width="60" type="index" fixed label="序号"></el-table-column>
          <el-table-column align="center" width="120" prop="name" label="姓名"></el-table-column>
          <el-table-column align="center" width="160" prop="account" label="手机号/账号"></el-table-column>
          <el-table-column
            align="center"
            width="180"
            prop="orgName"
            label="所属组织"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">{{row.orgName || '--'}}</template>
          </el-table-column>
          <el-table-column
            align="center"
            width="180"
            prop="orgPName"
            label="上级组织"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">{{row.orgPName || '--'}}</template>
          </el-table-column>
          <el-table-column align="center" width="160" prop="createTime" label="创建时间"></el-table-column>
          <el-table-column align="center" width="160" prop="status" label="状态">
            <template slot-scope="{ row }">
              <el-switch
                :disabled="row.adminType == 2"
                class="table-scope"
                v-model="row.status"
                :active-value="0"
                active-text="激活"
                active-color="#67C33A"
                :inactive-value="1"
                inactive-text="冻结"
                inactive-color="#F56C6C"
                @change="handleChangeStatus($event, row.id)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="260">
            <template slot-scope="{ row }">
              <div class="comm-operation">
                <span class="edit-btn mr-16" @click="$refs.grantDialogRef.open(row.id)">分配角色</span>
                <span class="edit-btn mr-16" @click="handleResetPwd(row.id)">重置密码</span>
                <span class="edit-btn mr-16" @click="$refs.infoDialogRef.open(row)">修改</span>
                <span v-if="row.adminType !== 2" class="delete-btn" @click="handleDelete(row.id)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" v-if="pageShow">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 50]"
            layout="total, prev, pager, next, sizes"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增/编辑角色弹窗 -->
    <InfoDialog ref="infoDialogRef" :orgTree="orgTree" @onSuccess="handleSuccess" />
    <!-- 分配角色弹窗 -->
    <GrantRoleDialog ref="grantDialogRef" />
  </div>
</template>

<script>
import { getUserList, getOrgTree, deleteUsers, postUserStatus, postResetPassword } from '@/api/main/system/userMana.js';
import { exportMethods } from '@/utils/exportMethods.js';
import InfoDialog from './infoDialog.vue';
import GrantRoleDialog from './grantRoleDialog.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'userMana',
  components: { InfoDialog, GrantRoleDialog },
  data() {
    return {
      total: 0,
      pageShow: true,
      queryForm: {
        orgId: '',
        searchValue: '',
        pageNo: 1,
        pageSize: 10
      },
      exportLoading: false,
      tableLoading: false,
      userList: [],
      selectedIdList: [],
      orgTree: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    };
  },
  computed: {
    ...mapGetters(['appInfo'])
  },
  created() {
    this.getUserListData();
    this.getOrgTreeData();
  },
  methods: {
    // 获取用户列表
    getUserListData() {
      this.tableLoading = true;
      getUserList(this.queryForm)
        .then(res => {
          this.userList = res.data.rows;
          this.total = res.data.totalRows;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 获取部门树
    getOrgTreeData() {
      getOrgTree({}).then(res => {
        this.orgTree = res.data;
        this.formatteTreeData(this.orgTree);
      });
    },
    // 处理 orgTree
    formatteTreeData(arr = []) {
      arr.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.formatteTreeData(item.children);
        } else if (item.children && item.children.length === 0) {
          delete item.children;
        }
      });
    },
    // 分页
    handleSizeChange(pageSize) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = pageSize;
      this.getUserListData();
    },
    handleCurrentChange(pageNo) {
      this.queryForm.pageNo = pageNo;
      this.getUserListData();
    },
    // 点击左侧 组织树
    handleNodeClick(data) {
      this.queryForm.orgId = data.id;
      this.queryForm.pageNo = 1;
      this.getUserListData();
    },
    handleSearch() {
      this.pageShow = false
      this.queryForm.pageNo = 1;
      this.getUserListData();
      this.$nextTick(() => {
        this.pageShow = true
      })
    },
    handleReset() {
      this.pageShow = false
      // 取消树结构选中
      this.$refs.orgTreeRef.setCurrentKey(null);
      this.queryForm.searchValue = '';
      this.queryForm.orgId = '';
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getUserListData();
      this.$nextTick(() => {
        this.pageShow = true
      })
    },
    // 列表选择
    handleSelectionChange(val) {
      this.selectedIdList = val.map(item => item.id);
    },
    // 点击删除
    handleDelete(data) {
      let ids;
      let message = '';
      if (typeof data === 'string') {
        ids = data;
        message = '删除用户成功！';
      } else if (Array.isArray(data)) {
        ids = data.join(',');
        message = '批量删除用户成功！';
      }

      this.$confirm('是否确认删除所选用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return deleteUsers({ ids, appId: this.appInfo.appId });
        })
        .then(res => {
          this.$message.success(message);
          this.getUserListData();
        })
        .catch(err => {
          if (err) return; // axios 统一处理了错误提示
        });
    },
    // 新增/编辑成功
    handleSuccess(data) {
      if (data) {
        this.$message.success('编辑用户成功！');
        this.getUserListData();
      } else {
        this.$message.success('新增用户成功！');
        this.handleReset();
      }
    },
    // 修改状态
    handleChangeStatus(status, id) {
      let str = '';
      switch (status) {
        case 0:
          str = '激活';
          break;
        case 1:
          str = '冻结';
          break;
      }
      postUserStatus({ status, id, appId: this.appInfo.appId }).then(res => {
        this.$message.success(`已${str}此用户！`);
      });
    },
    // 重置密码
    handleResetPwd(id) {
      this.$confirm('是否重置所选用户密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return postResetPassword({ id });
        })
        .then(res => {
          this.$message.success('重置密码成功！');
        })
        .catch(err => {
          if (err) return; // axios 统一处理了错误提示
        });
    },
    // 导出
    handleExport() {
      this.exportLoading = true;
      exportMethods('/sysUser/export', { idList: this.selectedIdList }).finally(() => {
        this.exportLoading = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-switch__label * {
  font-size: 12px;
  padding: 2px;
  font-weight: 400;
}
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
}
.edit-form {
  border-bottom: 1px solid #e8e8e8;
}
.content {
  padding: 16px;
  .right-table {
    overflow: hidden;
    .top-search {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
    }
  }
}
/deep/.el-tree {
  .el-tree-node__content {
    display: block !important;
  }
  .el-tree-node__children {
    overflow: visible !important;
  }
}
/deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  color: #117bf7;
}
.tree {
  width: 180px;
  float: left;
  border: 1px solid #dcdfe7;
  margin-right: 16px;
  padding: 12px;
  //height: 300px;
  min-height: calc(100vh - 300px);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  .tree-title {
    padding-bottom: 26px;
  }
}
.pagination {
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/deep/.table-scope {
  .el-switch__label--left {
    position: relative;
    left: 58px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__core {
    width: 52px !important;
  }

  .el-switch__label--right {
    position: relative;
    right: 57px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__label--right.is-active {
    z-index: 3;
    color: #fff !important;
  }

  .el-switch__label--left.is-active {
    z-index: 3;
    color: #fff !important;
  }
}
</style>
